<template>
  <b-container>
    <b-row
      align-h="between"
    >
      <b-col
        cols="12"
        class="d-flex align-items-center mb-1"
      >
        <h1 :class="[hveCultures.length == 1 ? 'align-self-baseline' : '', 'h2', 'inline', 'm-0']">
          Tableau de synthèse Haute Valeur Environnementale
        </h1>
        <div
          v-show="hveCultures.length == 1"
          class="inline mx-2 h4 align-self-baseline text-primary"
        >
          {{ localize(hveCultures[0]) }}
        </div>
        <b-dropdown
          v-show="hveCultures.length > 1"
          class="custom-dropdown ml-1"
          variant="outline-primary"
          :text="localize(currentHveCulture)"
        >
          <b-dropdown-item
            v-for="(culture, index) in hveCultures"
            :key="index"
            @click="$store.commit('verticalMenu/SET_HVE_CULTURE', culture)"
          >
            {{ localize(culture) }}
          </b-dropdown-item>
        </b-dropdown>
      </b-col>
    </b-row>

    <b-row align-v="center">
      <b-col
        cols="12"
        class="mb-1"
      >
        <feather-icon
          v-if="isHveHelpShown"
          icon="XCircleIcon"
          class="text-primary"
          size="16"
        />
        <feather-icon
          v-else
          icon="HelpCircleIcon"
          class="text-primary"
          size="16"
        />
        <b-link @click="toggleHveHelp">
          Qu'est-ce que la synthèse HVE ?
        </b-link>
      </b-col>

      <b-col
        v-if="isHveHelpShown"
        cols="12"
      >
        <b-card>
          Sur cette page, vous trouverez les scores que vous avez obtenus sur chacun des modules du troisième niveau de la certification Haute Valeur Environnementale HVE.<br><br>Pour que votre exploitation soit certifiable HVE, vous devez soit:<br>
          <ul>
            <li>obtenir un score supérieur ou égal à 10 sur chacun des 4 modules ci-contre (Option A)</li>
            <li>≥10% de sa SAU en IAE (Infrastructures Agro-écologiques) ou ≥ 50% de la SAU en prairies permanentes de plus de 5 ans + poids des intrants ≤ 30% du chiffres d'affaires (Option B).</li>
          </ul>

          Le pourcentage présent à coté des modules vous permet de connaître le nombre d'exploitants participants à ce projet pilote ayant validé ce module.
          Pour suivre et piloter vos progrès sur chacun des 4 modules, vous pouvez cliquer sur le nom du module et accéder à votre tableau de bord pour le module concerné. Ces tableaux de bord suivent les indicateurs que vous avez contribué à sélectionner en participant à notre questionnaire.
        </b-card>
      </b-col>
    </b-row>

    <section v-if="farmHveAudit && farmHveAudit.option_a">
      <b-row>
        <b-col>
          <b-alert
            v-if="farmHveAudit.option_a.passed"
            variant="success"
            class="p-1"
            show
          >
            Vous êtes certifiable HVE selon l'option A
          </b-alert>
          <b-alert
            v-else
            variant="warning"
            class="p-1"
            show
          >
            Vous n'êtes pas certifiable HVE selon l'option A
          </b-alert>
        </b-col>
      </b-row>
      <b-row v-if="modulesA">
        <b-col
          v-for="module in modulesA"
          :key="module.name"
          cols="4"
        >
          <router-link :to="{ name: 'farm-hve-' + module.slug}">
            <ModuleCardA :module="module" />
          </router-link>
        </b-col>
      </b-row>
    </section>

    <section v-if="farmHveAudit && farmHveAudit.option_b">
      <b-row>
        <b-col>
          <b-alert
            v-if="farmHveAudit.option_b.passed"
            variant="success"
            class="p-1"
            show
          >
            Vous êtes certifiable HVE selon l'option B
          </b-alert>
          <b-alert
            v-else
            variant="warning"
            class="p-1"
            show
          >
            Vous n'êtes pas certifiable HVE selon l'option B
          </b-alert>
        </b-col>
      </b-row>
      <b-row>
        <b-col
          v-for="module in modulesB.and"
          :key="module.indicator.id"
          cols="6"
        >
          <ModuleCardB :module="module" />
        </b-col>
      </b-row>
    </section>

    <section v-if="coopHveAudit">
      <b-row>
        <b-col cols="4">
          <b-card>
            <b-row align-v="start">
              <b-col>
                Exploitations certifiables HVE au sein de votre coopérative
              </b-col>
              <b-col
                cols="2"
                class="text-right"
              />
            </b-row>
            <b-row>
              <vue-apex-charts
                type="radialBar"
                height="280"
                :options="hveChartOptions"
                :series="hveChartData"
              />
            </b-row>
            <b-row>
              <b-col>
                <b-row
                  align-h="center"
                  class="h6 text-muted"
                >
                  Exploitations
                </b-row>
                <b-row
                  align-h="center"
                  class="h4 font-weight-bolder"
                >
                  {{ coopHveAudit.passing_farms_count + coopHveAudit.failing_farms_count }}
                </b-row>
              </b-col>
              <b-col>
                <b-row
                  align-h="center"
                  class="h6 text-muted"
                >
                  Certifiables
                </b-row>
                <b-row
                  align-h="center"
                  class="h4 font-weight-bolder"
                >
                  {{ coopHveAudit.passing_farms_count }}
                </b-row>
              </b-col>
            </b-row>
          </b-card>
        </b-col>
      </b-row>
    </section>
  </b-container>
</template>

<script>
import {
  BContainer, BRow, BCol, BAlert, BCard, BLink, BDropdown, BDropdownItem
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import ModuleCardA from "@/components/farm/ModuleCardA.vue";
import ModuleCardB from "@/components/farm/ModuleCardB.vue";
import { mapGetters, mapState } from "vuex";
import { getUserData } from "@/auth/utils";
import localize from "@/utils/localize";

export default {
  components: {
    BContainer,
    BRow,
    BCol,
    BAlert,
    BCard,
    BLink,
    VueApexCharts,
    ModuleCardA,
    ModuleCardB,
    BDropdown,
    BDropdownItem
  },
  data() {
    return {
      userData: getUserData(),
      isHveHelpShown: false,
      hveChartOptions: {
        colors: ["#28C76F"],
        plotOptions: {
          radialBar: {
            startAngle: -115,
            endAngle: 115,
            hollow: {
              size: "75%"
            },
            dataLabels: {
              show: true,
              value: {
                show: true,
                fontSize: "2.8em",
                offsetY: 0,
                fontFamily: "inherit",
                color: "#5e5873"
              },
              name: {
                show: false
              }
            }
          }
        },
        stroke: {
          lineCap: "round"
        }
      }
    };
  },
  computed: {
    ...mapState("farm", { farmHveAudit: "hveAudit" }),
    ...mapState("cooperative", { coopHveAudit: "hveAudit" }),
    ...mapState("verticalMenu", ["currentHveCulture", "hveCultures"]),
    ...mapGetters("farm", ["modulesA", "modulesB"]),
    hveChartData() {
      return [
        Math.floor((this.coopHveAudit.passing_farms_count / (this.coopHveAudit.failing_farms_count + this.coopHveAudit.passing_farms_count)) * 100)
      ];
    }
  },
  methods: {
    toggleHveHelp() {
      this.isHveHelpShown = !this.isHveHelpShown;
    },
    localize
  }
};
</script>
