<template>
  <b-card style="padding: -1em;">
    <b-row
      align-h="between"
      align-v="baseline"
      class="mb-1"
    >
      <b-col align-self="center">
        <h3 class="m-0">
          {{ module.name }}
        </h3>
      </b-col>
      <b-col
        cols="3"
        class="text-right"
        align-h="end"
      >
        <feather-icon
          v-if="module.passed"
          icon="CheckCircleIcon"
          size="45"
          class="text-success bg-light-success rounded-circle"
          style="padding: 0.6em;"
        />
        <feather-icon
          v-else
          icon="XIcon"
          size="45"
          class="text-warning bg-light-warning rounded-circle"
          style="padding: 0.6em;"
        />
      </b-col>
    </b-row>
    <b-row align-v="end">
      <b-col
        class="h1 font-weight-bolder"
        style="font-size: 2.6em;"
      >
        {{ modulePrettyNote }}
      </b-col>
      <b-col align-self="center">
        <b-row
          v-if="coopIndicator"
          align-v="center"
          align-h="end"
          no-gutters
        >
          <b-col class="text-secondary">
            Acquis. coop:
            {{ coopIndicator.pretty_passing_percent }}
          </b-col>
          <b-col cols="4">
            <vue-apex-charts
              type="radialBar"
              height="60"
              :options="chartOptions"
              :series="[coopIndicator.passing_percent]"
            />
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { mapState } from "vuex";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    VueApexCharts
  },
  props: {
    module: {
      type: Object,
      default: () => null
    }
  },
  data() {
    return {
      chartOptions: {
        grid: {
          padding: {
            top: -5,
            bottom: -5
          }
        },
        chart: {
          sparkline: {
            enabled: true
          }
        },
        plotOptions: {
          radialBar: {
            hollow: {
              size: "30%"
            },
            dataLabels: {
              show: false
            },
            track: {
              background: "#cccccc"
            }
          }
        },
        stroke: {
          lineCap: "round"
        }
      }
    };
  },
  computed: {
    ...mapState("cooperative", { coopHveAudit: "hveAudit" }),
    coopIndicator() {
      return this.coopHveAudit ? this.coopHveAudit.passing_by_indicator_group.find(i => i.slug === this.module.slug) : "";
    },
    modulePrettyNote() {
      return this.module.pretty_note || "N.C.";
    }
  },
  watch: {
    module() {
      this.formatChartStroke();
    }
  },
  created() {
    this.formatChartStroke();
  },
  methods: {
    formatChartStroke() {
      if (this.coopIndicator.passing_percent === 0) {
        this.chartOptions = {
          ...this.chartOptions,
          stroke: {
            lineCap: "butt"
          }
        };
      } else {
        this.chartOptions = {
          ...this.chartOptions,
          stroke: {
            lineCap: "round"
          }
        };
      }
    }
  }
};
</script>
