<template>
  <b-card>
    <b-row
      align-h="between"
      align-v="baseline"
    >
      <b-col align-self="center">
        <h3 class="m-0">
          {{ module.indicator.name }}
        </h3>
      </b-col>
      <b-col
        cols="3"
        class="text-right"
        align-h="end"
      >
        <feather-icon
          v-if="module.passed"
          icon="CheckCircleIcon"
          size="45"
          class="text-success bg-light-success rounded-circle"
          style="padding: 0.6em;"
        />
        <feather-icon
          v-else
          icon="XIcon"
          size="45"
          class="text-warning bg-light-warning rounded-circle"
          style="padding: 0.6em;"
        />
      </b-col>
    </b-row>
    <b-row class="mt-0">
      <b-col>
        {{ condition }}
      </b-col>
    </b-row>
    <b-row
      class="h1 font-weight-bolder mt-1"
      style="font-size: 2.6em;"
    >
      <b-col>
        {{ module.pretty_value_with_unit }}
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BCard, BRow, BCol } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BRow,
    BCol
  },
  props: {
    module: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    condition() {
      if (this.module.condition === "over") {
        return `Supérieur à ${this.module.pretty_target_value_with_unit}`;
      }
      return `Inférieur à ${this.module.pretty_target_value_with_unit}`;
    }
  }
};
</script>
